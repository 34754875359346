@import url('https://fonts.googleapis.com/css?family=Livvic:400,400i,700,700i&display=swap');

// Vars
:root {
  --color-one: hsl(204, 86%, 33%);
  --color-one-dark: hsl(204, 85%, 26%);
  --color-two: #cad72e;
  --color-two-light: hsl(64,67%,90%);
  --color-three: #261642;
  --color-three-light: hsl(221, 33%, 75%);
  --color-three-lightest: hsl(221, 20%, 95%);
}

// Reset
html {
  margin: 0;
  padding: 0;
  background: var(--color-three);
}

body {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.4;
  font-family: 'Livvic', sans-serif;
}

// Type
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1rem 0;

  &+p {
    margin-top: -0.5rem;
    font-size: 1.25em;
  }
}

p {
  margin: 0 0 1rem 0;
}

ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 1rem 0;

  li {
    padding-left: 1rem;
    flex-basis: 100%;
    display: block;
    margin-bottom: 0.5rem;
    background: url(/assets/images/bolt.svg) center left no-repeat;
    background-size: 1rem;
  }
}

// Layout

header {
  flex-shrink: 0;

  display: flex;
  flex-direction: column;

  background: var(--color-three);

  img {
    display: block;
    height: 4rem;
    margin-left: 1rem;
  }

  h1 span {
    display: none;
  }

  nav {
    position: relative;
    z-index: 2;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    background: var(--color-one);

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;
      padding: 0 1rem 0 2rem;
      color: rgba(255, 255, 255, 0.9);
      background: var(--color-one) url(/assets/images/bolt.svg) 1rem center no-repeat;
      background-size: auto 1rem;
      text-decoration: none;
      font-weight: bold;
      transition: all .2s ease-in-out;

      &:hover,
      &:focus {
        background-color: var(--color-one-dark);
        color: white;
      }
    }

    a[href="#appointment"] {
      top: -0.5rem;
      right: 0;
      position: absolute;
      padding: 0 1.5rem 0 0;
      background: var(--color-two);
      color: var(--color-three);
      height: 4rem;
      width: 7rem;
      text-align: right;
      border-radius: 2rem 0 0 2rem;


      &:hover,
      &:focus {
        transform: scale(1.1);
        background-color: white;
        color: var(--color-one);
      }

      svg {
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        height: 100%;
        pointer-events: none;
        // outline: 1px solid red;
        fill: var(--color-three);
        color: white;
      }
    }
  }
}

@media (min-width: 1000px) {
  header {
    img {
      margin-left: 1.5rem;
    }
    nav {
      padding-left: 1rem;
    }
  }
}

main {
  background: white;
  flex-grow: 1;
  scroll-snap-type: y proximity;
  overflow-y: scroll;
}

section {
  box-sizing: border-box;
  position: relative;
  scroll-snap-align: start end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  overflow-x: hidden;

  h1,
  h2,
  h3,
  h4 {
    color: var(--color-one);
  }
}

@media (min-width: 1000px) {
  section {
    padding: 3rem;
  }
}


#cover-image {
  flex-grow: 1;
  padding: 0;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: 20vh;
    object-fit: cover;
    object-position: center center;
  }
  svg {
    position: absolute;
    width: auto;
    height: 110%;
    right: 0;
    bottom: -1rem;
    pointer-events: none;
    // outline: 1px solid red;
    fill: var(--color-one-dark);
    color: var(--color-two);
  }
}

@media (min-width: 1000px) {
  #cover-image {
    img {
      width: 100%;
      height: 100%;
    }
    svg {
      right: -1rem;

    }
  }
}

#services {
  position: relative;
  margin-bottom: 0;
  z-index: 1;

  background: var(--color-three-lightest);
  color: var(--color-three);

  img {
    margin-top: -1rem;
    margin-bottom: 1rem;
    width: 100%;
    height: auto;
  }

  ul li {
    background: url(/assets/images/bolt-blue.svg) center left no-repeat;
  }
}

#about {
  background: var(--color-one-dark);

    h1,
    h2,
    h3,
    h4 {
      color: var(--color-two);
    }

    p {
      color: rgba(255, 255, 255, 0.75)
    }
  h3 small {
    display: block;
    color: white;
  }
  .avatar {
    float: left;
    width: 5rem;
    border-radius: 50%;
    background: white;
    overflow: hidden;
    margin-right: 1rem;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

#appointment {
  min-height: calc(100vh - 11rem);
  background: white;
  display: flex;
  flex-direction: column;
  h2 { flex-shrink: 0; }
  .appointment-form {
    margin: 0 0 0 -15px;
    position: relative;
    width: calc(100% + 30px);
    height: 52rem;
    flex-grow: 1;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
    }
  }

}

@media (min-width: 1000px) {
  #appointment {
    .appointment-form {
      height: auto;
    }
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  background: var(--color-two);
  color: var(--color-three);
  padding: 0.5rem 1rem 0.5rem 1rem;

  a {
    display: block;
    font-weight: bold;
    padding: 0 1rem 0 0;
    text-decoration: none;
    color: var(--color-three);
    &:first-of-type {
      font-size: 1.25em;
    }
    &.btn {
      padding: 0.5rem 1rem;
      background: var(--color-one);
      border-radius: 1rem;
      margin-left: auto;
      color: white;
    }
  }
}

#copyright {
  padding: 0 1rem;
  color: var(--color-three);
  padding-top: 1rem;
  padding-bottom: 4rem;
  background: var(--color-three-lightest);
  width: 100%;

  p {
    margin: 0;
  }
}

@media (min-width: 1000px) {
  main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    section {
      flex-basis: 50%;
    }
    section:nth-child(3) {
      flex-basis: 30%;
    }
    section:nth-child(4) {
      flex-basis: 70%;
    }
  }
  #copyright {
    flex-basis: 100%;
  }
}

@media (min-width: 1200px) {
  ul li {
    flex-basis: 50%;
  }
}